import { TRACKING_EVENTS } from '@/core/tracking/constants';

import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import Link from 'next/link';
import { useCallback, useMemo } from 'react';

import { useGetWorkspacePathname } from '@/app/workspaces/hooks/useGetWorkspacePathname';
import { track } from '@/core/tracking';

type Props = {
    /**
     * Optional href to override the back button's Link href.
     * By default, the back button links to the "home" of the
     * active / last active workspace.
     */
    overrideBackLink?: string;
};

export const BackButton = ({ overrideBackLink }: Props) => {
    const { getHomeWorkspacePathname } = useGetWorkspacePathname();
    const backLink = useMemo(() => {
        return overrideBackLink ?? getHomeWorkspacePathname();
    }, [getHomeWorkspacePathname, overrideBackLink]);

    const trackClick = useCallback(() => {
        track(TRACKING_EVENTS.navigation.actions.backButtonClicked, {});
    }, []);

    return (
        <Link
            href={backLink}
            className="flex items-center justify-center rounded-lg p-2.5 text-gray-600 hover:bg-gray-100"
            onClick={trackClick}
        >
            <ArrowLeftIcon className="size-5" />
        </Link>
    );
};
